// api 서버 주소

// export const server = "http://localhost:8081";
//export const server = "http://192.168.1.155:8081";

//export const server = "http://192.168.1.195:8081"; // sy

//export const server = "http://13.209.84.213:14301";
export const server = "https://api.ziple.biz"; //real
//export const server = "https://dev-api.ziple.biz";

//export const FaceBookAppId = "178849639482980";
export const FaceBookAppId = "164274751010328"; //real

export const KAKAO_API_KEY = "fd965e68f1aa42d66aaf07bbc351fc0b";
export const KAKAO_JS_KEY = "0edc8d4474271811c9a295f09920b1e5";

export const zoom_apiKey = "EyfvdHnhQoS_bttY7n_f0Q";
export const leaveUrl = "https://www.ziple.biz";
export const zoom_apiSecret = "oVPG6wIp1hvj7XsqpWK6JEuf8Dmdt5bwLNZA";
