import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Accordion from "./Accordion";
import "../../../css/view/ViewPage.css";

function Notice() {
  const { t } = useTranslation();
  const [notice, setNotice] = useState([
    {
      noticeNo: 1,
      title: "공지입니다",
      contents:
        "안녕하세요 자이플 입니다\n\n" +
        "자이플 안드로이드 앱 서비스의 페이스북 로그인 오류가 발견되어 수정후 반영완료되었습니다 (iOS 및 웹서비스는 이상없습니다)\n" +
        "서비스 이용에 불편을 드려 죄송합니다\n\n" +
        "2021.10.12 에 플레이스토어에 v.1.0.44로 등록되었습니다\n\n" +
        "고맙습니다",
    },
    {
      noticeNo: 2,
      title: "크라우드 펀딩 진행에 따른 신주발행 공고",
      contents:
        "2021년 10월 1일 개최한 주식회사 지식피플의 임시주주총회에서 아래와 같이 신주발행을 결의하였음을 공고합니다.\n\n" +
        "<크라우드 펀딩 진행에 따른 신주발행 공고>\n" +
        " ----------\n" +
        "\t1. 자금조달의 목적  :       자이플 서비스 고도화 및 운영자금 \n" +
        "\t2. 신주의 종류                보통주\n" +
        "\t3. 신주의 총수                60,000주\n " +
        "\t4. 신주의 액면가액         100원\n " +
        "\t5. 신주의 발행가액         1100원\n " +
        "\t6. 청약기간                    2021년 10월 22일 ~ 2021년 11월 10일\n " +
        "\t7. 청약의 최소단위         1000주 \n" +
        "\t8. 신주의 배정방법         정관에 의거 신주식 발행하여 제3자 배정 \n" +
        "\t9. 주금납입일                 2021년 11월 22일\n" +
        "\t10. 청약 취급처              온라인소액투자중개업자 (주)오마이컴퍼니\n" +
        "\t11. 주금 납입은행            기업은행 판교제2테크노밸리지점 \n" +
        "\t12. 신주의 배당기산일     2021년 1월 1일\n" +
        "\t13. 신주권 교부일            2021년 12월 9일\n" +
        "\t14. 기타사항 \n" +
        "\t\t1) 이 유상증자와 관련하여 임시주주총회에서 정하지 아니한 사항은 대표자에게 위임함 \n" +
        "\t\t2) 위 일정은 관계기관과의 협의과정에서 변경될 수 있음\n\n" +
        "2021년 10월 1일\n" +
        "주식회사 지식피플\n" +
        "----------",
    },
    {
      noticeNo: 3,
      title: "주주총회 소집 통지서",
      contents:
        "주식회사 지식피플 주주님의 건승과 무궁한 발전을 기원합니다.\n" +
        "다음과 같이 주주총회를 개최하기로 결의하여 정관 제20조에 의하여 주주님께 \n" +
        "소집통지서를 보내드리오니 꼭  참석하시어 주주권리를 행사하시기 바랍니다.\n" +
        "\n" +
        " - 다음- \n" +
        "\n" +
        "1. 주주총회 일시 : 2022년  2월  23일 오전  10시\n" +
        "\n" +
        "2. 총회장소 : 경기도 성남시 분당구 판교로 289번길 20\n" +
        "\t\t경기스타트업캠퍼스  2동  5층 (삼평동)\n" +
        "\t\t주식회사 지식피플 회의실\n" +
        "\n" +
        "3. 주주총회 안건\n" +
        "\t- 제 1호 의안  신주발행의 건\n" +
        "\n" +
        "2022. 2. 8.\n" +
        "주식회사 지식피플(Ziple Co.,Ltd.)\n" +
        "\n" +
        "경기도  성남시  분당구  판교로 289번길 20 경기스타트업  2동  5층 (삼평동)\n" +
        "\n" +
        "공동대표이사 이정섭 \n" +
        "공동대표이사 윤재원",
    },
    {
      noticeNo: 4,
      title: "[지식피플]본사 주소 변경안내",
      contents:
        "안녕하세요, 주식회사 지식피플 입니다.\n" +
        "이번에 저희 회사의 본사 주소가 변경되었음을 알려드립니다.\n" +
        "새로운 주소는 <경기도 성남시 분당구 판교로 289번길 20, 스타트업캠퍼스 2동 8층 5호>으로 이용에 착오 없으시길 바랍니다.\n" +
        "저희 (주)지식피플은 이번 주소 변경을 통해 보다 더 발전된 환경에서 더 나은 서비스를 제공할 수 있도록 노력하겠습니다. 새로운 본사 주소로의 방문 및 문의사항이 있으신 분들은 언제든지 아래의 연락처로 문의해주시기 바랍니다.\n" +
        "문의 전화번호: 031-890-1422\n" +
        "이메일: cs@ziple.biz\n" +
        "2023.05.19",
    },
    {
      noticeNo: 5,
      title: "주주총회 소집 통지서",
      contents:
        "주주총회 소집 통지서\n" +
        "\n" +
        "주식회사 지식피플 주주님의 건승과 무궁한 발전을 기원합니다.   \n" +
        "다음과 같이 주주총회를 개최하기로 결의하여 정관 제20조에 의하여 주주님께 소집통지서를 보내드리오니 꼭 참석하시어 주주권리를 행사하시기 바랍니다.\n" +
        "\n" +
        "-다음-\n" +
        "\n" +
        "\n" +
        "1. 주주총회 일시 : 2024년 10월 15일  오후 2시\n" +
        "\n" +
        "2. 총회장소 : 경기도 성남시 분당구 판교로289번길 20\n" +
        "\t\t경기스타트업캠퍼스 2동 8층5호실(삼평동)\n" +
        "\t\t주식회사 지식피플 회의실\n" +
        "\n" +
        "3. 주주총회 안건\n" +
        "\n" +
        "\t-제1호 의안  파산 신청의 건\n" +
        "\n" +
        "\n" +
        "\n" +
        "2024. 10. 2.\n" +
        "\n" +
        "주식회사 지식피플(Ziple Co.,Ltd.)\n" +
        "경기도 성남시 분당구 판교로289번길 20,\n" +
        "경기스타트업캠퍼스 2동 8층5호실(삼평동)\n" +
        "공동대표이사 이정섭\n" +
        "공동대표이사 윤재원,\n",
    },
    // {noticeNo:6},
    // {noticeNo:7},
    // {noticeNo:8},
    // {noticeNo:9},
    // {noticeNo:10},
    // {noticeNo:11},
    // {noticeNo:12}
  ]);
  let lang = localStorage.getItem("language");
  useEffect(() => {
    // noticeList().then((res) => {
    //   setNotice(res.lists);
    // });
  }, []);

  return (
    <div className="white view-page-box">
      <div className="faq-page-title">
        <p>{t("NOTICE")}</p>
      </div>
      <div>
        {notice.map((notice) => (
          <Accordion
            type={"notice"}
            key={notice.noticeNo}
            title={lang === "ko" ? notice.title : notice.titleEn}
            children={lang === "ko" ? notice.contents : notice.contentsEn}
          />
        ))}
      </div>
    </div>
  );
}

export default Notice;
