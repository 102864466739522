import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withRouter, useParams } from "react-router-dom";

import "../../../css/question/Detail.css";

import Back from "../../../images/back.png";
import Dot from "../../../images/dot.png";
import Profile from "../../../images/profile.png";

import Modal from "../../../common/Modal";
import Alert from "../../../common/AlertModal";
import Item from "./ReplyItem";
import SubMenu from "./SubButton";
import Loading from "../../../common/Loading";

import {
  getQnaDetailList,
  qnaReplyInsert,
  qnaReplyModify,
  qnaDelete,
  getReplyList,
} from "../../../services/qnaService";
import {
  choiceRandomColor,
  ideaDivNm,
  formatDotDate,
} from "../../../common/util";
import { userSelect } from "../../../services/userService";

function Detail({ history }) {
  const { t } = useTranslation();
  const no = useParams().id;
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [subMenu, setSubMenu] = useState(false);
  const [action, setAction] = useState("");
  const [data, setData] = useState("");
  let userInfo = JSON.parse(localStorage.getItem("ziple_user"));
  const [qna, setQna] = useState({
    nickNm: "",
    detail: {},
    gravatar: 0,
    color: "",
  });
  const [reply, setReply] = useState({});
  const [replyList, setReplyList] = useState([]);
  const [form, setForm] = useState({
    content: "",
    index: 0,
    user: "",
    divCode: "",
  });

  const checkForm = () => {
    if (form.content === "") {
      setMessage(t("ContentIn"));
      setModal(true);
    } else {
      // if(action === t("ReplyUp")){
      //   setMessage(t("ReplyUp"));
      //   setModal(true);
      // }else{
      setMessage(t("ReplyIn"));
      setModal(true);
      // }
    }
  };

  const qnaReply = async () => {
    try {
      await getReplyList({
        qnaNo: no,
        userNo: userInfo.userNo,
        div: "detail",
      }).then((res) => {
        setReplyList(res.replyList);
        setForm({ ...form, content: "" });
      });
    } catch (e) {
      console.log(e);
    }
  };

  const qnaDetail = async () => {
    try {
      setLoading(true);
      await getQnaDetailList({
        qnaNo: no,
        userNo: userInfo.userNo,
      }).then((res) => {
        setQna({
          nickNm: res.question.nickNm,
          detail: res.question,
          gravatar: res.question.profileUrl.indexOf("gravatar"),
          color: choiceRandomColor(res.question.userNo),
        });
        setReplyList(res.replyList);
      });

      await userSelect(userInfo.userNo).then((res) => {
        setForm({
          ...form,
          content: "",
          index: no,
          user: res.userNo,
          divCode: res.userDivCd,
        });
        setLoading(false);
      });
    } catch (e) {}
  };

  useEffect(() => {
    qnaDetail();
  }, []);

  useEffect(() => {
    if (!modal) {
      if (message === t("ReplyIn")) {
        qnaReplyInsert({
          content: form.content,
          qnaNo: form.index,
          userNo: form.user,
          userDivCd: form.divCode,
        }).then((res) => {
          if (res.resCd === "0000") {
            qnaReply();
          } else {
            setMessage(t("ReplyFail"));
            setModal(true);
          }
        });
      } else if (message === t("ReplyUp")) {
        qnaReplyModify({
          content: data.content,
          qnaNo: form.index,
          qnaReplyNo: data.replyNo,
          userNo: form.user,
          userDivCd: form.divCode,
        }).then((res) => {
          if (res.resCd === "0000") {
            setAction("");
            qnaReply();
          } else {
            setMessage(t("ReplyFail"));
            setModal(true);
          }
        });
      } else if (message === t("DeleteQnASuccess")) {
        history.push("/question/list");
      }
    }
  }, [modal]);

  useEffect(() => {
    if (action === "질문수정") {
      history.push("/question/modify/" + no);
    }
    if (action === "질문삭제") {
      qnaDelete({
        qnaNo: no,
        userNo: 0,
      }).then((res) => {
        // console.log(res);
        if (res.resCd === "0000") {
          setMessage(t("DeleteQnASuccess"));
          setModal(true);
        }
      });
    }
    if (action === t("ReplyUp")) {
      setMessage(t("ReplyUp"));
      setModal(true);
      //  window.scrollTo(0,document.body.scrollHeight);
      //   setForm({
      //     ...form,
      //     content:data.content
      //   })
    }
    if (action === "답글삭제") {
      qnaReply();
      setAction("");
    }
  }, [action]);

  if (loading) {
    return (
      <div className="white question-write-wrap">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="white question-write-wrap">
        <div className="question-back-box" onClick={() => history.goBack()}>
          <img className="question-write-back-btn" src={Back} alt="뒤로가기" />
        </div>
        <div className="question-detail-wrap">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="qna-detail-profile-wrap">
              {qna.gravatar === -1 ? (
                <img
                  src={qna.detail.profileUrl}
                  alt="유저 프로필"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = Profile;
                  }}
                  className="question-detail-profile-image"
                />
              ) : (
                <div
                  className="qna-detail-color-profile"
                  style={{ background: qna.color }}
                >
                  {qna.nickNm.substr(0, 1)}*
                </div>
              )}
            </div>
            <div className="qna-detail-writer">
              <div className="flex">
                <p>{qna.detail.title}</p>
                <p className="qna-category">
                  {t(ideaDivNm(qna.detail.ideaDiv))}
                </p>
                {userInfo.userNo === qna.detail.userNo && (
                  <div>
                    <img
                      className="sub-menu-image"
                      src={Dot}
                      alt="수정_삭제"
                      onClick={() => setSubMenu(!subMenu)}
                    />
                    {subMenu && <SubMenu setAction={setAction} type="질문" />}
                  </div>
                )}
              </div>
              <p>
                {/* {t(ideaDivNm(qna.detail.ideaDiv))} |{" "} */}
                {qna.nickNm.substr(0, 1) + "**"}{" "}
                <span style={{ opacity: "0.5" }}>|</span>{" "}
                {formatDotDate(qna.detail.regDate)}
              </p>
              <div className="qna-detail-content">{qna.detail.content}</div>
            </div>
          </div>
        </div>
      
            {replyList.map((reply, index) => (
              <Item
                reply={reply}
                setReply={setReply}
                setData={setData}
                key={index}
                user={userInfo.userNo}
                action={action}
                setAction={setAction}
              />
            ))}
         
        <div className="question-reply-insert-box flex">
          <textarea
            value={form.content}
            onChange={(e) => setForm({ ...form, content: e.target.value })}
          />
          {action === t("ReplyUp") ? (
            <div onClick={checkForm}>{t("Modify")}</div>
          ) : (
            <div onClick={checkForm}>{t("Write")}</div>
          )}
        </div>
      </div>

      {modal && (
        <Modal>
          <Alert msg={message} setShowModal={setModal} />
        </Modal>
      )}
    </>
  );
}

export default withRouter(Detail);
