import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { getQnaList } from "../../../services/qnaService";
import { formatDate, choiceRandomColor } from "../../../common/util";

import Icon from "../../../images/back.png";
import Profile from "../../../images/profile.png";

function Question() {
  const { t } = useTranslation();
  const [qnaList, setQnaList] = useState([]);

  useEffect(() => {
    getQnaList({
      title: "",
      userNm: "",
      userNo: "",
      pageNo: 1,
      content: "",
      ideaDiv: "Y",
      pageSize: "3",
      sortDbField: "reg_date",
      sortDirection: "desc",
    }).then((res) => {
      setQnaList(res.lists);
    });
  }, []);

  const pageMove = (no) => {
    window.location.href = "/question/detail/" + no;
  };

  return (
    <div className="main-question">
      <div className="flex">
        <p className="main-question-title">{t("Main-QnA")}</p>
        <div
          className="main-view-more flex"
          onClick={() => {
            window.location.href = "/question/list";
          }}
        >
          <p>{t("View-More")}</p>
          <img src={Icon} />
        </div>
      </div>

      <div className="flex">
        {qnaList.map((qna, index) => (
          <div
            className="main-question-wrap"
            key={index}
            onClick={() => {
              pageMove(qna.qnaNo);
            }}
          >
            <div className="flex" style={{ textAlign: "left" }}>
              {qna.profileUrl == null || qna.profileUrl.indexOf("gravatar") === -1 ? (
                <img
                  className="profile-image2"
                  src={qna.profileUrl != null ? qna.profileUrl : Profile} 
                  alt="유저 프로필"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = Profile;
                  }}
                />
              ) : (
                <div
                  className="qna-color-profile2"
                  style={{ background: choiceRandomColor(qna.userNo) }}
                >
                  {qna.nickNm.substr(0, 1)}*
                </div>
              )}
              <div className="main-qna-title-wrap">
                <p className="main-qna-title">{qna.title}</p>
                <p className="main-qna-name">
                  {qna.nickNm != null ?qna.nickNm.substr(0, 1) + "**"  : "***"} | {formatDate(qna.regDate)}
                </p>
              </div>
            </div>
            <div className="main-qna-content">{qna.content}</div>

            <div className="main-qna-reply">
              {qna.replyProfileUrl != null && (
                <>
                  {qna.replyProfileUrl.indexOf("gravatar") === -1 ? (
                    <img
                      className="profile-image2"
                      src={qna.replyProfileUrl}
                      alt="유저 프로필"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = Profile;
                      }}
                    />
                  ) : (
                    <div
                      className="qna-color-profile2"
                      style={{ background: choiceRandomColor(qna.replyUserNo) }}
                    >
                      {qna.replyNickNm.substr(0, 1)}*
                    </div>
                  )}
                </>
              ) }
              <div className="main-qna-reply-wrap">
                {qna.replyContent !== null && qna.replyContent}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Question;
