import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { infoProcess, formatDate } from "../../../common/util";
import { BookMarkUp } from "../../../services/infoService";

import Modal from "../../../common/Modal";
import Alert from "../../../common/AlertModal";

import arrow from "../../../images/arrow.png";

function InfoItem({ item }) {
  const { t } = useTranslation();
  const path = useLocation().pathname;
  let userInfo = JSON.parse(localStorage.getItem("ziple_user"));
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [user, setUser] = useState(0);

  const handlePopUp = async (checkNo, val) => {
    window.open(item.link);
    let form = {
      checkNo: checkNo,
      infoNo: val,
      userNo: user,
      checkDiv: "2",
    };
    await BookMarkUp(form);
  };

  useEffect(() => {
    if (userInfo !== null) {
      setUser(userInfo.userNo);
    }
  }, []);

  return (
    <div
      className="info-main-item-box"
      onClick={() => handlePopUp(item.checkNo, item.infoNo)}
    >
      <div>
        <div className="flex">
          {/* 상단 뱃지 */}
          {item.infoDiv === "01" && (
            <div className="info-main-div" style={{ background: "#00489C" }}>
              {t("IPNews")}
            </div>
          )}
          {item.infoDiv === "02" && (
            <div className="info-main-div" style={{ background: "#9C0000" }}>
              IP R&D
            </div>
          )}
          {item.infoDiv === "03" && (
            <div className="info-main-div" style={{ background: "#009C80" }}>
              {t("IPCompetition")}
            </div>
          )}

          {/* 접수중/접수마감 */}
          {item.infoDiv !== "01" && (
            <div
              className={
                item.process === "1"
                  ? "info-process-on info-main-status"
                  : "info-process-off info-main-status"
              }
            >
              <span>{t(infoProcess(item.process))}</span>
            </div>
          )}
        </div>

        <div className="info-main-subject">{item.subject}</div>
        <div className="info-main-contents">{item.contents}</div>
        <div className="info-main-date">
          {formatDate(item.regDate)} | {item.organizer}
        </div>
      </div>

      <div className="info-main-img">
        {item.imgUrl != null && item.imgUrl != "null" && (
          <img src={item.imgUrl} alt="info_img" />
        )}
        {/* <div className="info-detail-button">
          <button onClick={() => handlePopUp(item.checkNo, item.infoNo)}>
            자세히보기
            <img src={arrow} />
          </button>
        </div> */}
      </div>

      {modal && (
        <Modal>
          <Alert msg={message} setShowModal={setModal} />
        </Modal>
      )}
    </div>
  );
}

export default InfoItem;
