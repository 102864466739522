import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import "../../../css/question/Write.css";

import Back from "../../../images/back.png";

import Modal from "../../../common/Modal";
import Alert from "../../../common/AlertModal";
import Loading from "../../../common/Loading";
import Confirm from '../../../common/ConfirmModal';
import { qnaPost } from "../../../services/qnaService";
import { userSelect } from "../../../services/userService";

function Write({ history }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);

  const [message, setMessage] = useState("");
  let userInfo = JSON.parse(localStorage.getItem("ziple_user"));
  const [user, setUser] = useState({});
  const [form, setForm] = useState({
    title: "",
    category: "",
    public: "Y",
    content: "",
  });

  const checkForm = () => {
    if (form.title === "") {
      setMessage(t("TitleIn"));
      setModal(true);
      return;
    }
    if (form.category === "") {
      setMessage(t("CategoryIn"));
      setModal(true);
      return;
    }
    if (form.content === "") {
      setMessage(t("ContentIn"));
      setModal(true);
      return;
    }
    qnaPost({
      title: form.title,
      ideaDiv: form.category,
      content: form.content,
      toPublic: form.public,
      userNm: user.userNm,
      userNo: user.userNo,
      qnaNo: 0,
    }).then((res) => {
      if (res.resCd === "0000") {
        if(form.public === 'Y'){
          setMessage(t('ContactEndTrue'));
          setModal2(true);
        }else{
          setMessage(t("ContactEnd"));
        setModal(true);
        }
       
      }
    });
  };

  const getUserInfo = async () => {
    try {
      setLoading(true);
      await userSelect(userInfo.userNo).then((res) => {
        setUser(res);
        setLoading(false);
      });
    } catch (e) {}
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (!modal) {
      if (message === t("ContactEnd")) {
        history.push("/question/list");
      }
    }
  }, [modal]);

  const contactEnd =()=>{
    setMessage(t("ContactEnd"));
    setModal(true);
  }
  
  if (loading) {
    return (
      <div className="white question-write-wrap">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="white question-write-wrap">
        <div className="question-back-box" onClick={() => history.goBack()}>
          <img className="question-write-back-btn" src={Back} alt="뒤로가기" />
        </div>
        <div className="question-write-content-box">
          <p className="question-form-title">{t("Title")}</p>
          <div className="question-write-content">
            <input
              type="text"
              placeholder={t("TitleIn")}
              maxLength="16"
              value={form.title}
              onChange={(e) => setForm({ ...form, title: e.target.value })}
            />
          </div>
          <div>
            <p className="question-form-title">{t("Category")}</p>
            <div className="question-write-content">
              <select
                value={form.category}
                onChange={(e) => setForm({ ...form, category: e.target.value })}
              >
                <option value="">{t("CategoryIn")}</option>
                <option value="01">{t("Patent")}</option>
                <option value="02">{t("UtilityModel")}</option>
                <option value="03">{t("Trademark")}</option>
                <option value="04">{t("Design")}</option>
                <option value="05">{t("PreviousInvestigation")}</option>
                <option value="06">{t("Review")}</option>
                <option value="08">{t("Other")}</option>
              </select>
            </div>
          </div>
          <div>
            <p className="question-form-title">{t("Scope")}</p>
            <div className="question-write-radio-box">
              <input
                type="checkbox"
                id="public"
                className="question-write-input"
                checked={form.public === "Y" ? true : false}
                onClick={(e) => {
                  if (form.public === "N") {
                    setForm({
                      ...form,
                      public: "Y",
                    });
                  }
                }}
                onChange={(e) => {
                  if (form.public === "N") {
                    setForm({
                      ...form,
                      public: "Y",
                    });
                  }
                }}
              />
              <label htmlFor="public">
                <span></span>
                {t("Public")}
              </label>
              <input
                type="checkbox"
                id="private"
                className="question-write-input"
                checked={form.public === "N" ? true : false}
                onChange={(e) => {
                  if (form.public === "Y") {
                    setForm({
                      ...form,
                      public: "N",
                    });
                  }
                }}
                onClick={(e) => {
                  if (form.public === "Y") {
                    setForm({
                      ...form,
                      public: "N",
                    });
                  }
                }}
              />
              <label htmlFor="private">
                <span></span>
                {t("notPublic")}
              </label>
            </div>
          </div>
          <div className="question-write-content-textarea">
            <textarea
              placeholder={t("ContentIn")}
              value={form.content}
              maxLength="1000"
              onChange={(e) => setForm({ ...form, content: e.target.value })}
            />
          </div>
        </div>
      

      <div className="question-more-view" onClick={checkForm}>
        {t("NewQnA")}
      </div>
      </div>
      {modal && (
        <Modal>
          <Alert msg={message} setShowModal={setModal} />
        </Modal>
      )}

      {modal2 && (
        <Modal>
          <Confirm
            msg={message}
            setShowModal={setModal2}
            setResult={contactEnd}
          />
        </Modal>
      )}
    </>
  );
}

export default withRouter(Write);

