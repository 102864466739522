import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "../../../css/common/Calendar.css";
import Reservation from "../Reservation";
function Time({ rezTime, setRezTime, noRezTime }) {
  const timeList = [
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
  ];
  console.log(noRezTime.length);

  return (
    <div className="Time">
      {timeList.map((time, i) => (
        <>
          {noRezTime.length > 0 ? (
            <>
              {!noRezTime.includes(time) ? (
                <button
                  key={i}
                  className={rezTime === time ? "Timer select" : "Timer"}
                  onClick={() => {
                    setRezTime(time);
                  }}
                >
                  {time}
                </button>
              ) : (
                <button key={i} className="Timer noSelect">
                  {time}
                </button>
              )}
            </>
          ) : (
            <button
              key={i}
              className={rezTime === time ? "Timer select" : "Timer"}
              onClick={() => {
                setRezTime(time);
              }}
            >
              {time}
            </button>
          )}
        </>
      ))}
    </div>
  );
}

export default withRouter(Time);
