import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { getProfessional, delegateBtnNm } from "../../../common/util";

import {
  delegateInfo,
  getDelegateClick,
  delegateLikeInsert,
} from "../../../services/delegateService";
import { userSelect } from "../../../services/userService";
import ShareButtons from "../../../common/component/ShareButton";
import Modal from "../../../common/Modal";
import Alert from "../../../common/AlertModal";

import bookMarkOn from "../../../images/bookmark2.png";
import bookMarkOff from "../../../images/bookmark.png";
import phoneImg from "../../../images/Phone_d.png";
import share from "../../../images/share_d.png";
import webSiteImg from "../../../images/website.png";
import mailImg from "../../../images/Email_d.png";
import messageImg from "../../../images/message.png";
import viewMore from "../../../images/back.png";

import "../../../css/delegate/Delegate.css";

function DelegateItem({ history, item, setItem }) {
  const { t } = useTranslation();
  let lang = localStorage.getItem("language");
  let userInfo = JSON.parse(localStorage.getItem("ziple_user"));
  const [profieldUrl, setProfieldUrl] = useState([]);
  const [user, setUser] = useState({});
  const [field, setField] = useState([]);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (lang === "ko") {
      const parse = getProfessional(item.professionalField);
      setProfieldUrl(parse.flagUrls);
      setField(parse.field);
    } else {
      const parse = getProfessional(item.professionalFieldEn);
      setProfieldUrl(parse.flagUrls);
      setField(parse.field);
    }
  }, [item, lang]);

  const getUserInfo = async () => {
    try {
      await userSelect(userInfo.userNo).then((res) => {
        setUser({
          no: userInfo.userNo,
          email: res.userEmail,
          name: res.userNm,
          phone: res.phoneNo,
          nickname: res.nickNm,
        });
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (userInfo != null) {
      getUserInfo();
      getDelegateInfo();
    }
  }, []);
  const shareMenu = (content, subject, div, value, adNo, adNm) => {
    delegateClick(div, value, adNo, adNm);
    setMessage(t("Share"));
    setModal2(true);
    // socialSharing.share(content, subject, null, value);
  };

  const delegateClick = (div, value, adNo, adNm) => {
    if (div === 9) {
      window.gtag(
        "event",
        adNm + "대리인 " + delegateBtnNm(div.toString()) + " 버튼 클릭",
        {
          eventCategory: div,
          eventLabel:
            adNm + "대리인 " + delegateBtnNm(div.toString()) + " 버튼 클릭",
          eventAction: div + "버튼 클릭",
          eventValue: 1,
        }
      );
      // 북마크
      let form = {
        userNo: userInfo !== null ? userInfo.userNo : 0,
        action: div,
        no: adNo,
        cateDiv: "brandMain",
      };
      if (userInfo === null) {
        setMessage(t("LoginErrorMsg"));
        setModal(true);
      } else {
        getDelegateClick(form).then((res) => {
          if (res.resCd === "0000") {
            delegateLikeInsert(
              adNo,
              userInfo.userNo,
              item.likeYn === null || item.likeYn === "N" ? "Y" : "N"
            ).then((res) => {
              if (res.resCd === "0000") {
                getDelegateInfo();
              }
            });
          }
        });
      }
    }
    if (value != null) {
      if (div === 1) {
        window.location.href = "tel:" + value;
      } else if (div === 2) {
        window.location.href =
          "mailto:" +
          value +
          "?subject=[ziple]대리인에게 질문입니다. " +
          "&body=[자이플]에서 보낸 메일입니다.";
      } else if (div === 4 || div === 5 || div === 6 || div === 7) {
        window.open(value);
      } else {
        console.log("shareMenu" + div);
      }

      window.gtag(
        "event",
        adNm + "대리인 " + delegateBtnNm(div.toString()) + " 버튼 클릭",
        {
          eventCategory: div,
          eventLabel:
            adNm + "대리인 " + delegateBtnNm(div.toString()) + " 버튼 클릭",
          eventAction: div + "버튼 클릭",
          eventValue: 1,
        }
      );

      let form = {
        userNo: userInfo !== null ? userInfo.userNo : 0,
        action: div,
        no: adNo,
        cateDiv: "brandMain",
      };
      getDelegateClick(form).then((res) => {});
    }
  };
  const getDelegateInfo = () => {
    delegateInfo({
      adNo: item.adNo,
      userNo: userInfo !== null ? userInfo.userNo : 1,
    }).then((res) => {
      if (item.adNo === res.data.adNo) {
        item.likeYn = res.data.likeYn;
        setItem({ likeYn: res.data.likeYn });
        // console.log(item.likeYn);
      }
    });
  };
  const getContent = (item) => {
    if (item.shareUrl != null) {
      return (
        item.regUserNm +
        "%0d%0a" +
        item.phoneNo +
        "%0d%0a" +
        item.subject +
        "%0d%0a" +
        item.contents +
        "%0d%0a" +
        item.shareUrl
      );
    } else {
      return (
        item.regUserNm +
        "%0d%0a" +
        item.phoneNo +
        "%0d%0a" +
        item.subject +
        "%0d%0a" +
        item.contents +
        "%0d%0a"
      );
    }
  };

  return (
    <>
      {/* 일반 변리사 */}
      {item.adGrade === "1" && (
        <div className="delegate-ad-div">
          <div className="flex padlf2">
            <div className="delegate-adGrade-member">MEMBER</div>
            <div className="delegate-ad-button">
              <button
                className="btn-trans"
                onClick={() =>
                  delegateClick(9, item.likeYn, item.adNo, item.adNm)
                }
              >
                {item.likeYn === "Y" && userInfo != null ? (
                  <img src={bookMarkOn} />
                ) : (
                  <img src={bookMarkOff} />
                )}
              </button>
            </div>
          </div>
          <div className="delegate-ad-subject padlf">
            {lang === "ko" ? item.subject : item.subjectEn}
          </div>
          <img src={item.profileUrl} className="delegate-ad-profile1" />
          <div className="delegate-ad-subject padlf">
            {item.regUserNm}&nbsp;
            {item.delegateDiv === "1" ? (
              <span>{t("Attorney")}</span>
            ) : (
              <span>{t("Lawyer")}</span>
            )}
          </div>
          <div
            className="padlf delegate-ad-cate-div"
            style={{ justifyContent: "center" }}
          >
            {profieldUrl.map((img, k) => (
              <div className="delegate-ad-cate" key={k}>
                {field[k]}
              </div>
            ))}
          </div>
          <div className="delegate-ad-contnets padlf">
            {lang === "ko" ? item.contents : item.contentsEn}
          </div>
          <div className="delegate-ad-profile-btn">
            <button
              className="btn-trans"
              onClick={() =>
                delegateClick(1, item.phoneNo, item.adNo, item.regUserNm)
              }
            >
              <img src={phoneImg} className="delegate-ad-click-button" />
            </button>
          </div>
        </div>
      )}

      {/* 프리미엄 변리사 */}
      {item.adGrade === "2" && (
        <div
          className="delegate-ad-div premium-display"
          style={{ background: "#efefef", overflow:'hidden' }}
        >
          <div className="">
            <div className="flex padlf2">
              <div className="delegate-adGrade-premium">PREMIUM</div>
              <div className="delegate-ad-button">
                <button
                  className="btn-trans"
                  onClick={() =>
                    delegateClick(9, item.likeYn, item.adNo, item.adNm)
                  }
                >
                  {item.likeYn === "Y" && userInfo != null ? (
                    <img src={bookMarkOn} />
                  ) : (
                    <img src={bookMarkOff} />
                  )}
                </button>
                <button
                  className="btn-trans"
                  onClick={() =>
                    shareMenu(
                      item.contnets,
                      item.subject,
                      11,
                      item.shareUrl,
                      item.adNo,
                      item.regUserNm
                    )
                  }
                >
                  <img src={share} />
                </button>
              </div>
            </div>
            <div className="delegate-ad-subject-premium padlf">
              {lang === "ko" ? item.subject : item.subjectEn}
            </div>
            <div className="delegate-ad-name-premium padlf">
              {item.regUserNm}&nbsp;
              {item.delegateDiv === "1" ? (
                <span>{t("Attorney")}</span>
              ) : (
                <span>{t("Lawyer")}</span>
              )}
              <span
                style={{
                  marginLeft: "10px",
                  fontSize: "10px",
                  fontWeight: 400,
                }}
              >
                {item.companyAddr != null &&
                  item.companyAddr.substr(0, item.companyAddr.indexOf(" ", 6))}
              </span>
            </div>
            <div
              className="padlf delegate-ad-cate-div"
              style={{ justifyContent: "left" }}
            >
              {profieldUrl.map((img, k) => (
                <div className="delegate-ad-cate" key={k}>
                  {field[k]}
                </div>
              ))}
            </div>
            <div className="delegate-ad-contnets-pre padlf">
              {lang === "ko" ? item.contents : item.contentsEn}
            </div>
          </div>

          <div className={lang === 'ko'?"flex delegate-ad-primum-profile":"flex delegate-ad-primum-profile-en"}>
            <div className="delegate-ad-click-button">
              {item.phoneNo != null && (
                <button
                  className="btn-trans"
                  onClick={() =>
                    delegateClick(1, item.phoneNo, item.adNo, item.regUserNm)
                  }
                >
                  <img src={phoneImg} />
                </button>
              )}
              {item.siteUrl != null && (
                <button
                  className="btn-trans"
                  onClick={() =>
                    delegateClick(7, item.siteUrl, item.adNo, item.regUserNm)
                  }
                >
                  <img src={webSiteImg} />
                </button>
              )}
              {item.userEmail != null && (
                <button
                  className="btn-trans"
                  onClick={() =>
                    delegateClick(2, item.userEmail, item.adNo, item.regUserNm)
                  }
                >
                  <img src={mailImg} />
                </button>
              )}
              {item.kakaoInfo != null && (
                <button
                  className="btn-trans"
                  onClick={() =>
                    delegateClick(4, item.kakaoInfo, item.adNo, item.regUserNm)
                  }
                >
                  <img src={messageImg} />
                </button>
              )}
            </div>
            
              <div
                className="delegate-ad-profile"
                // style={{
                //   background:
                //     "url(" + item.profileUrl + ") 0% 0% / 100% 100% no-repeat",
                //     backgroundSize:'contain'
                // }}
              >
                <img src={item.profileUrl} style={{width:'100%'}}/>
              </div>
            {/* <div className="delegate-ad-detail">
              <div
                onClick={() => {
                  window.location.href = "/info/detail/" + item.userNo;
                  window.scrollTo(0, 0);
                }}
              >
                {t("View-More")}
                <img src={viewMore} />
              </div>
            </div> */}
          </div>
          <div style={{ height:'30px', display:"flex", position:'absolute', bottom:0, width:'100%' ,cursor:'pointer'}}>
            <div className="delegate-ad-detail"  style={{backgroundColor:"#0E5EC7", width:'50%', color:'#fff'}} onClick={() => {
                  window.location.href = "/reservation/" + item.userNo;
                  window.scrollTo(0, 0);
                }}><b>상담 예약 하기</b></div>
            <div className="delegate-ad-detail" style={{backgroundColor:"#aeaeae", width:'50%', color:'#f2f2f2'}}  onClick={() => {
                  window.location.href = "/info/detail/" + item.userNo;
                  window.scrollTo(0, 0);
                }}><b> {t("Delegate-View-More")}</b></div>
          </div>
        </div>
      )}

      {modal && (
        <Modal>
          <Alert msg={message} setShowModal={setModal} />
        </Modal>
      )}
      {modal2 && (
        <Modal>
          <ShareButtons
            msg={message}
            setShowModal2={setModal2}
            link={item.shareUrl}
            phoneNo={item.phoneNo}
            content={getContent(item)}
            user={user}
          ></ShareButtons>
        </Modal>
      )}
    </>
  );
}

export default withRouter(DelegateItem);
