import React from "react";
import { useTranslation } from "react-i18next";

import Service_KR from "../../../images/main_service_kr.jpg";
import Serbice_EN from "../../../images/main_service_en.jpg";

function Service() {
  const { t } = useTranslation();
  const language = localStorage.getItem("language");
  
  return (
    <div className="ziple-service">
      <p>{t("Main-Service")}</p>
      {language === "ko" ? (
        <img src={Service_KR} alt="서비스 이미지" />
      ) : (
        <img src={Serbice_EN} alt="서비스 이미지" />
      )}
    </div>
  );
}

export default Service;
