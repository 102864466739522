import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { delegateList } from "../../../services/delegateService";

import "../../../css/common/Advertise.css";
import DelegateItem from "./MDelegateItem";
import icon from "../../../images/ad_icon.png";
import viewMore from '../../../images/arrow-down.png';
import { useLocation } from "react-router-dom";

function Delegate() {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);

  const path = useLocation().pathname;
  //const [category, setCategory] = useState(match.params.keyword);
  const [category, setCategory] = useState("");
  const [delegate, setDelegate] = useState([]);
  const [delegateSize, setDelegateSize] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({});
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  const responsive = {
    0: {
      items: 1,
    },
    420: {
      items: 2,
    },
    840: {
      items: 3,
    },
    1280: {
      items: 4,
    },
  };

  useEffect(() => {
    setLoading(true);
    delegateList({
      userDiv: "1", // 1 일반인, 2 대리인
      infoDiv: "", // 01 특허뉴스, 02 R&D, 03 경진대회
      subject: "",
      userNo: 1,
      pageNo: page,
      pageSize: 8,
      sortDbField: "reg_date",
      sortDirection: "desc",
    }).then((res) => {
      if (page === 1) {
        setDelegate(res.lists);
      } else {
        setDelegate(delegate.concat(res.lists));
      }
      setDelegateSize(res.listCnt);
      setLoading(false);
    });
  }, [path]);

  const MoreView = async () => {
   setPage( page+1);
    // if (delegateSize > delegate.length) {
    //   delegateList({
    //     userDiv: "1", // 1 일반인, 2 대리인
    //     infoDiv: "", // 01 특허뉴스, 02 R&D, 03 경진대회
    //     subject: "",
    //     userNo: 1,
    //     pageNo: page,
    //     pageSize: 8,
    //     sortDbField: "reg_date",
    //     sortDirection: "desc",
    //   }).then((res) => {
    //     if (page === 1) {
    //       setDelegate(res.lists);
    //     } else {
    //       setDelegate(delegate.concat(res.lists));
    //     }
    //     setDelegateSize(res.listCnt);
    //     setPage(page + 1);
    //     setLoading(false);
    //   });
    // }
  };
  return (
    <>
      <div className="main-delegate">
        <div className="main-delegate-title flex">
          <p>{t("Main-Patent attorney-Ad")}</p>
          <img
            src={icon}
            alt="광고 아이콘"
            onMouseOver={() => setModal(true)}
            onMouseLeave={() => setModal(false)}
          />
          {modal && (
            <div className="advertise-wrap">
              <div className="dark-grey advertise-alert">{t("AdvertMsg")}</div>
            </div>
           )}
        </div>

        <div className="main-delegate-div">
          {delegate.map((item, index) => (
            <>
            {index < page * 8 && index < delegateSize &&(
            <DelegateItem
              key={index}
              item={item}
              setItem={setItem}
            />
            )}
            </>
          ))}

          {delegateSize === 0 && (
            <div className="delegate-list-empty">{t("Patent-No")}</div>
          )}
        </div>
        {delegateSize > page * 8 && (
          <div
            className="delegate-more-view"
            onClick={() => {
              MoreView();
            }}
          >
            {t("View-More")}
            <img src={viewMore}/>
          </div>
        )}
      </div>
    </>
  );
}
export default Delegate;
