import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import US from "../images/us.svg";
import KO from "../images/kr.svg";
import Logo from "../images/logo.png";
import Triangle from "../images/triangle.png";

import SubMenu from "./component/TopSubMenu";
import MobileSubMenu from "./component/MobileSubMenu";

import "../css/common/TopBar.css";

import { userSelect } from "../services/userService";

function TopBar({ history }) {
  const { t, i18n } = useTranslation();
  const [subMenu, setSubMenu] = useState(false);
  const [view, setView] = useState(false);
  const [name, setName] = useState("");
  let user = JSON.parse(localStorage.getItem("ziple_user"));
  let language = localStorage.getItem("i18nextLng");
  let location = useLocation().pathname;
  const changeLanguage = (lng) => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng);
  };

  const pageMove = (location) => {
    history.push(location);
  };

  useEffect(() => {
    if (user !== null) {
      userSelect(user.userNo).then((res) => {
        if (res) {
          setName(res.nickNm);
        }
      });
    }
    if(localStorage.getItem("language") == null){
      localStorage.setItem("language", language);
    }
  });

  return (
    <div className="top-bar-wrap">
      <div className="flex top-bar">
        <img
          src={Logo}
          alt="ziple_logo"
          className="logo-image logo-margin"
          onClick={() => pageMove("/")}
        />

        <div className="flex top-sub-menu">
          {user == null ? (
            <div onClick={() => pageMove("/login")}>
              {t("Login")}/{t("Sign")}
            </div>
          ) : (
            <>
              <div
                onClick={() => setSubMenu(!subMenu)}
                className="top-bar-login-menu"
              >
                {name}&nbsp;
                <img src={Triangle} alt="세모 이미지" />
              </div>
              {subMenu && (
                <>
                  <SubMenu setSubMenu={setSubMenu} setView={setView} />
                  <MobileSubMenu setSubMenu={setSubMenu} setView={setView} />
                </>
              )}
            </>
          )}
          <div className="top-bar-language-select">
            {language === "ko" ? (
              <img
                alt="ko"
                src={KO}
                className="top-bar-language-image"
                onClick={() => changeLanguage("en")}
              />
            ) : (
              <img
                alt="us"
                src={US}
                className="top-bar-language-image"
                onClick={() => changeLanguage("ko")}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex top-menu">
        <div
          className={
            location.indexOf("info") > -1 ? "actice-top-bar" : undefined
          }
          onClick={() => {pageMove("/info")}}
        >
          {t("Patent-Info")}
        </div>
        <div
          className={
            location.indexOf("lounge") > -1 ? "actice-top-bar" : undefined
          }
          onClick={() => {pageMove("/lounge/01")}}
        >
          {t("InfoList")}
        </div>
        <div
          className={
            location.indexOf("question") > -1 ? "actice-top-bar" : undefined
          }
          onClick={() => {pageMove("/question/list")}}
        >
          {t("Online-Consulting")}
        </div>
        <div
          className={
            location.indexOf("faq") > -1 ? "actice-top-bar" : undefined
          }
          onClick={() => pageMove("/faq")}
        >
          {t("FAQ")}
        </div>
        {/* {view && (
          <>
            <ViewMore setView={setView} setSubMenu={setSubMenu} />
            <MobileViewMore setView={setView} setSubMenu={setSubMenu} />
          </>
        )} */}
      </div>
    </div>
  );
}

export default withRouter(TopBar);
