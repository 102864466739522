import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import One from "../../../images/banner1.jpg";
// import Two from "../../../images/banner2.png";
// import Three from "../../../images/banner3.png";

function BannerTop() {
  return (
    <div className="top-banner-box">
      {/* <AliceCarousel
        // autoPlay={true}
        // autoPlayInterval="8000"
        infinite={true}
        disableButtonsControls={true}
        disableDotsControls={true}
      > */}
        <img src={One} className="slider-img" alt="상단 배너" />
        {/* <img src={Two} className="slider-img" alt="상단 배너" />
        <img src={Three} className="slider-img" alt="상단 배너" /> */}
      {/* </AliceCarousel> */}
    </div>
  );
}

export default BannerTop;
