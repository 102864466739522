import React from "react";
import { useTranslation } from "react-i18next";

import "../../../css/infoLounge/MenuBar.css";

function MenuBar({ menu, setMenu }) {
  const { t } = useTranslation();

  return (
    <div className="lounge-menu-bar">
      <p>{t("Info-Lounge-Title")}</p>
      <div className="flex lounce-menu-box">
        <div
          className={
            menu === "01"
              ? "lounge-active lounge-menu-title"
              : "lounge-menu-title"
          }
          onClick={() => setMenu("01")}
        >
          {t("IPNews")}
        </div>
        <div
          className={
            menu === "02"
              ? "lounge-active lounge-menu-title"
              : "lounge-menu-title"
          }
          onClick={() => setMenu("02")}
        >
          IP R&D
        </div>
        <div
          className={
            menu === "03"
              ? "lounge-active lounge-menu-title"
              : "lounge-menu-title"
          }
          onClick={() => setMenu("03")}
        >
          {t("IPCompetition")}
        </div>
      </div>
    </div>
  );
}

export default MenuBar;
