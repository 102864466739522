import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { server } from "../../../config/config";

import "../../../css/rez/Rez.css";

import Loading from "../../../common/Loading";
import { phoneHyphen } from "../../../util/inputUtil";
import { rezDetail, zoomInfo } from "../../../services/reservationService";
import { leaveUrl, zoom_apiKey, zoom_apiSecret } from "../../../config/config";
import { ZoomMtg } from "@zoomus/websdk";

ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.9/lib", "/av");

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");

function Detail({ history }) {

  const no = useParams().id;
  const [loading, setLoading] = useState(false);

  const [detail, setDetail] = useState({
    statusCd: "",
    deUserNm: "",
    dePhone: "",
    rezTimeDiv: "",
    rezTime: "",
    rezDate: "",
    userNm: "",
    phone: "",
    content: "",
  });
  let userInfo = JSON.parse(localStorage.getItem("ziple_user"));
  // setup your signature endpoint here: https://github.com/zoom/meetingsdk-sample-signature-node.js
  //var signatureEndpoint = "";
  var apiKey = zoom_apiKey;
  const [zomInfo, setZomInfo] = useState({
    meetingNumber: "",
    passWord: "",
  });
  var role = 0;
  var userEmail = "";
  var registrantToken = "";

  const getRezDetail = async () => {
    try {
      await setLoading(true);
      rezDetail({
        rezNo: no,
        userNo: userInfo.userNo,
      }).then((res) => {
        setDetail({
          ...detail,
          statusCd: res.data.statusCd,
          deUserNm: res.data.deUserNm,
          dePhone: phoneHyphen(res.data.dePhone),
          rezTimeDiv: res.data.rezTimeDiv,
          rezTime: res.data.rezTime,
          rezDate: res.data.rezDate,
          userNm: res.data.userNm,
          phone: res.data.phone,
          content: res.data.content,
          rezNo:res.data.rezNo,
          userNo:res.data.userNo
        });
        setLoading(false);
      });

      // await userSelect(userInfo.userNo).then((res) => {
      //   setForm({
      //     ...form,
      //     content: "",
      //     index: no,
      //     user: res.userNo,
      //     divCode: res.userDivCd,
      //   });
      //   setLoading(false);
      // });
    } catch (e) {}
  };

  useEffect(() => {
    getRezDetail();
  }, []);

  useEffect(() => {
    getZomInfo();
  }, []);
  const getZomInfo = () => {
    zoomInfo().then((res) => {
      setZomInfo({
        ...zomInfo,
        meetingNumber: res.data.roomNumber,
        passWord: res.data.passWord,
      });
    });
  };

  if (loading) {
    return (
      <div className="rez-detail-wrap">
        <Loading />
      </div>
    );
  }
 const gotoPay=()=>{
   let rezMoney= detail.rezTimeDiv==='15'? 25000:45000;
  window.open(server+"/pg/web?Prdtnm="+detail.deUserNm+" 변리사 영상상담 예약&Price="+rezMoney+"&UserNm="+detail.userNm+"&UserId="+detail.userNo+"&No="+detail.rezNo);

 }
  const getSignature = (e) => {
    console.log('getSignature')
    ZoomMtg.generateSignature({
      meetingNumber: zomInfo.meetingNumber,
      apiKey: apiKey,
      apiSecret: zoom_apiSecret,
      role: role,
      success: function (res) {
        startMeeting(res.result);
      },
    });
  };

  const startMeeting = (signature) => {
    document.getElementById("zmmtg-root").style.display = "block";
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => { 
        console.log(success);
        ZoomMtg.join({
          signature: signature,
          meetingNumber: zomInfo.meetingNumber,
          userName: detail.userNm,
          apiKey: apiKey,
          userEmail: userEmail,
          passWord: zomInfo.passWord,
          tk: registrantToken,
          success: (success) => {
            console.log('success',success);
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  };

  return (
    <>
      <div className="rez-detail-top ">상담 예약내역 확인</div>
      <div className="rez-detail-wrap">
        <div className="rez-detail-info-div">
          <div className="rez-detail-info-title">상담 상태</div>
          <div className="rez-detail-info-content">{detail.statusCd}</div>
        </div>
        <div className="rez-detail-info-div">
          <div className="rez-detail-info-title">상담 예약 정보</div>
          <div className="flex ">
            <div className="rez-detail-info-contentT">변리사</div>
            <div className="rez-detail-info-content">{detail.deUserNm}</div>
          </div>
          <div className="flex">
            <div className="rez-detail-info-contentT">종류</div>
            <div className="rez-detail-info-content">
              {detail.rezTimeDiv}분 영상상담
            </div>
          </div>
          <div className="flex">
            <div className="rez-detail-info-contentT">날짜</div>
            <div className="rez-detail-info-content">{detail.rezDate}</div>
          </div>
          <div className="flex">
            <div className="rez-detail-info-contentT">시간</div>
            <div className="rez-detail-info-content">
              {detail.rezApm === "am" ? "오전" : "오후"} {detail.rezTime}
            </div>
          </div>
          <div className="flex">
            <div className="rez-detail-info-contentT">연락처</div>
            <div className="rez-detail-info-content">{detail.dePhone}</div>
          </div>
        </div>

        <div className="rez-detail-info-div">
          <div className="rez-detail-info-title">상담 예약 정보</div>
          <div className="flex">
            <div className="rez-detail-info-contentT">의뢰인</div>
            <div className="rez-detail-info-content">{detail.userNm}</div>
          </div>
          <div className="flex">
            <div className="rez-detail-info-contentT">연락처</div>
            <div className="rez-detail-info-content">{detail.phone}</div>
          </div>
        </div>
        <div className="rez-detail-info-div">
          <div className="rez-detail-info-title">상담 요청 내용</div>
          <div className="rez-detail-info-content">
            <p>{detail.content}</p>
          </div>
        </div>

        {detail.statusCd !== "예약요청" && (
          <div className="rez-detail-info-div">
            <div className="rez-detail-info-title">결제 정보</div>
            <div className="flex">
              <div className="rez-detail-info-contentT">결제 방법</div>
              <div className="rez-detail-info-content">
                {detail.pay === "cn" ? "체크카드/신용카드" : "무통장"}
              </div>
            </div>
            <div className="flex">
              <div className="rez-detail-info-contentT">결제 금액 </div>
              <div className="rez-detail-info-content">
                {detail.rezTimeDiv === "15" ? "25,000원" : "50,000원"}
              </div>
            </div>
          </div>
        )}
         {detail.statusCd === "예약요청" && (
          <div className="rez-detail-info-div">
             <button
              style={{
                border: "1px solid #b3b3b3",
                backgroundColor: "blue",
                padding: 5,
                borderRadius: 5,
                color: "white",
              }}
              onClick={()=>gotoPay()}
            >
              결제하기
            </button>
          </div>
        )}
        {detail.statusCd === "상담수락" && (
          <div style={{ margin: 20 }}>
            <button
              style={{
                border: "1px solid #b3b3b3",
                backgroundColor: "blue",
                padding: 5,
                borderRadius: 5,
                color: "white",
              }}
              onClick={getSignature}
            >
              상담하기
            </button>
            <p playsInline></p>
          </div>
        )}
      </div>

     
    </>
  );
}

export default withRouter(Detail);
