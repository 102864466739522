import React from "react";

import "../../css/main/Main.css";

import BannerTop from "./components/BannerTop";
import FieldIcon from "./components/FieldIcon";
import Delegate from "./components/MDelegate";
import Youtube from "./components/Youtube";
import Service from "./components/Service";
import Map from "./components/Map";
import Information from "./components/Information";
import Question from "./components/Question";
import BannerBottom from "./components/BannerBottom";

function Main() {
  return (
    <div className="main">
      <BannerTop />
      <Delegate />
      <Information />
      <Question />
      <Youtube />
      <Service />
      <BannerBottom />
    </div>
  );
}

export default Main;
